.sidebar-inverted {
    position: fixed;
    height: 100%;
    width: 20%;
    background: transparent linear-gradient(0deg, #06091D 0%, #0E3D90 59%, #125DDC 100%, #125FE2 100%) 0% 0% no-repeat padding-box;
    transform: matrix(1, 0, 0, 1, 0, 0);
    z-index: 2;
}

.sidebar {
    position: relative;
    display: block;
    width: 100%;
    height: 100%;
    /* background-color: #06091D; */
    transform: matrix(1, 0, 0, 1, 0, 0);
}

.sidebar-company-logo {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 15%;
    /* background-color: bisque; */
}

.sidebar-company-logo img {
    position: relative;
    width: 70px;
    height: 70px;
}

.sidebar-menu {
    display: block;
    position: relative;
    width: 100%;
    height: 85%;
    /* background-color: aqua; */
}

.sidebar-menu-1 {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    height: 85%;
    /* background-color: rgb(0, 17, 255); */
    padding: 10px;
}

.outter-box {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: center;
    width: 100%;
    height: 30px;
    background-color: #3CBBE5;
    border-radius: 2px;
    font-size: 11px;
    color: white;
    cursor: pointer;
}

.outter-box img {
    position: relative;
    height: 12px;
    width: 12px;
    margin-right: 10px;
    margin-left: 10px;
}

.outter-box p {
    margin-top: auto;
    margin-bottom: auto;
}


.sidebar-menu-2 {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    height: 15%;
    /* background-color: rgb(0, 0, 0); */
    padding: 10px;
}

.navbar {
    position: fixed;
    background-color: #FFFFFF;
    width: 100%;
    height: 70px;
    z-index: 1;
    margin: 0;
    padding: 0;
    border-bottom: 1px solid black;
}

.navbar-inner {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: end;
    align-items: center;
    width: 100%;
    height: 100%;
    /* background-color: #125DDC; */
    padding-right: 100px;
}

.navbar-inner-bell-icon {
    position: relative;
    width: 2%;
    height: 40%;
    margin-right: 35px;
    /* background-color: yellow; */
}

.navbar-inner-bell-icon img {
    position: relative;
    width: 100%;
    height: 100%;
    /* margin-right: 35px;
    background-color: yellow; */
    z-index: 0;
    margin-top: -60px;
}

.navbar-inner-bell-icon p {
    position: relative;
    background-color: #F13E3E;
    border-radius: 50%;
    font-size: 10px;
    border: 1px solid #FFFFFF;
    color: #FFFFFF;
    width: 70%;
    z-index: 1;
    margin-left: 12px;
}

.profile-button {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    /* background-color: yellow; */

}

.profile-button-img-container {
    position: relative;
    height: 45px;
    width: 45px;
    /* background-color: #06091D; */
    border-radius: 50%;
    margin-right: 10px;
}

.profile-button-img-container img {
    position: relative;
    height: 100%;
    width: 100%;
    /* background-color: #06091D; */
    border-radius: 50%;
    /* margin-right: 10px; */
}

.profile-button-text-container {
    line-height: 0;
    font-size: 15px;
    display: flex;
    flex-direction: column;
    align-items: start;
    /* background-color: #06091D; */
}

.data-container {
    position: fixed;
    background-color: #ff0000;
    width: 80%;
    height: 587px;
    z-index: -1;
    margin-left: 20%;
    bottom: 0;
}