.padding{
    padding: 26px;
}
.modal-outter {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 10;
    background-color: yellow;
    opacity: 0.5;
}

.modal-inner {
    position: relative;
    width: 500px;
    height: auto;
}
span{
    color: white ;
}