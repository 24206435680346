.price {
    margin: 10px;
}

.packlist {
    /* background-color: aqua; */
    padding: 0;
    border-radius: 25px;
    height: 500px;
}

.package-title {
    background: transparent linear-gradient(91deg, #3CBBE5 0%, #007AB6 100%) 0% 0% no-repeat padding-box;
    border-radius: 25px 25px 0px 0px;
    margin-bottom: 0;
}

.title {
    margin-bottom: 40px;
    margin-left: 10px;
}

.package-title h6 {
    color: #FFFFFF;
}

.package-title h6 {
    padding: 15px 0 15px 0;
}

.container {
    position: relative;
    display: flex;
    flex-direction: row;
    font-size: 12px;
    /* background-color: beige; */
    width: 100%;
    justify-content: center;
    padding: 0 20px 0 20px;
}

.content-text{
    position: relative;
    /* width: 100%; */
    /* background-color: blue; */
    word-wrap: break-word;
    text-align: start;
    padding: 0;
    margin: 0;
}

.description-container {
    /* background-color: pink; */
    margin: 0;
    padding: 0;
    max-height: 280px;
    width: 100%;
    overflow-y: scroll;
    padding-top: 5px;
}

.description-container::-webkit-scrollbar {
    visibility: hidden;
  }

  .package-price-container {
    display: flex;
    flex-direction: column;
    /* background-color: blue; */
    padding: 0;
    margin: 0;
    height: 80px;
    padding-top: 10px;
  }

  .package-price-container p, .price {
    margin: 0;
    padding: 0;
  }

  .package-price-container p {
    font-size: 15px;
    color: #667080;
  }
  
  .package-btn {
    margin-top: 12px;
    margin-left: auto;
    margin-right: auto;
    text-decoration: none;
  }

  .custom-column-gap {
    margin-left: 20px;
    margin-right: 40px;
    padding: 0px;
    background-color: none;
    box-shadow: 5px 10px 18px #88888885;
    border-radius: 25px;
  }
  
  .custom-column-gap:last-child {
    margin-right: 0; /* Remove right margin from the last div */
  }