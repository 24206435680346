.payment-details {
    background-color: yellow;
}

.card-element-container {
    background-color: #FFFFFF;
    border-radius: 50px;
    padding: 15px;
    margin: 7px 0 7px 0;  
}

.card-container, .exp-cvc {
    position: relative;
    width: 100%;
    background-color: pink;
}

.form-container {
    background-color: yellow;
    width: 500px;
}

.form-container label {
    font-size: 15px;
}

.exp-cvc {
    display: flex;
    flex-direction: row;
}

.exp, .cvc {
    position: relative;
    width: 50%;
}

.exp {
    padding-right: 10px;
}

.cvc {
    padding-left: 10px;
}

.form-title {
    font-size: 32px;
    font-weight: bolder;
}